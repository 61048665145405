import "./contact.css";
import { ReactElement } from "react";
import SideBar from "../../components/SideBar";
import { Typography } from "@mui/material";
import { MenuProps } from "../../components/Menu";

interface Props {
  menu: ReactElement<MenuProps>;
}

function ContactSection(props: Props): ReactElement<Props> {
  return (
    <div className="contact">
      <SideBar
        title="Contact"
        color="white"
        backgroundColor="primary"
        menu={props.menu}
      />
      <div className="contact__right-container">
        <div className="contact__right-text-container">
          <Typography variant="subtitle1">
            If you want to start your journey to a high quality modern website.
            Feel free to email us.
          </Typography>
          <Typography color="primary">
            <a href="mailto:contact@upgetgo.com">contact@upgetgo.com</a>
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default ContactSection;
