import "./side-bar.css";
import { Typography } from "@mui/material";
import { ReactElement } from "react";
import { MenuProps } from "../Menu";

interface Props {
  title: string;
  subtitle?: string;
  color: "white" | "black";
  backgroundColor: "primary" | "white" | "secondary";
  mobileBackgroundColor?: "primary" | "white" | "secondary";
  mobileColor?: "white" | "black";
  menu: ReactElement<MenuProps>;
}

const getBackgroundColorStyle = (
  color: "primary" | "white" | "secondary"
): string => {
  switch (color) {
    case "primary":
      return "primary-background";
    case "secondary":
      return "secondary-background";
    default:
      return "white-background";
  }
};

const getColorStyle = (color: "white" | "black"): string => {
  switch (color) {
    case "black":
      return "black-color";
    default:
      return "white-color";
  }
};

function SideBar(props: Props): ReactElement<Props> {
  const backgroundColor = getBackgroundColorStyle(props.backgroundColor);
  const mobileBackgroundColor = props.mobileBackgroundColor
    ? `mobile-${getBackgroundColorStyle(props.mobileBackgroundColor)}`
    : "";
  const color = getColorStyle(props.color);
  const mobileColor = props.mobileColor
    ? `mobile-${getColorStyle(props.mobileColor)}`
    : "";

  return (
    <div className={`side-bar ${backgroundColor} ${mobileBackgroundColor}`}>
      <div>
        <Typography
          align="center"
          component="h1"
          variant="h1"
          className={`${color} ${mobileColor}`}
        >
          {props.title}
        </Typography>
        {props.subtitle && (
          <Typography
            align="center"
            component="p"
            variant="subtitle1"
            className={`${color} ${mobileColor}`}
          >
            {props.subtitle}
          </Typography>
        )}
      </div>
      <div className="side-bar__menu-container">{props.menu}</div>
    </div>
  );
}

export default SideBar;
