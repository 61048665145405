import "./about.css";
import { ReactElement } from "react";
import { Typography } from "@mui/material";
import SideBar from "../../components/SideBar";
import { MenuProps } from "../../components/Menu";

interface Props {
  menu: ReactElement<MenuProps>;
}

interface StepProcessType {
  header: string;
  content: string;
}

const steps: StepProcessType[] = [
  {
    header: "1. First Meeting",
    content:
      "We will discuss your vision for the website, the structure, the pages, the colour scheme and any existing websites you want to draw inspiration from.",
  },
  {
    header: "2. Work Proposal Review and Quote",
    content:
      "Using the information in our first meeting, we will get together proposal of work, highlighting all the activities we will do to build and publish the website, this will include a quote for our services.",
  },
  {
    header: "3. Gathering information for initial web design",
    content:
      "If you agree to the proposal and quote, we will list out the necessary text content and images needed for us to build the first draft of the website.",
  },
  {
    header: "4. Demo and review of the websites first draft",
    content:
      "Once the first draft has been completed, we will meet again and we will demo the websites structure, content and user experience.",
  },
  {
    header: "5. Amendments to website and subsequent demos and review",
    content:
      "Once the first draft has been demoed, we may discuss amendments and/or updates to the websites structure, which will be worked on and then reviewed again.",
  },
  {
    header: "6. Final sign off of website",
    content:
      "Once you are happy, and all amendments have been completed. The website design will be signed off and we will proceed to making the website live.",
  },
  {
    header:
      "7. Publish website and connect to your website domain name (Website address)",
    content:
      "The final stage will be making the website live to the world, we will perform the various configurations on the websites server and domain name system to make this so.",
  },
];

function StepProcess(props: StepProcessType): ReactElement<StepProcessType> {
  return (
    <>
      <br />
      <br />
      <Typography variant="h6">
        <strong>{props.header}</strong>
      </Typography>
      <br />
      <Typography variant="subtitle1">{props.content}</Typography>
    </>
  );
}
function AboutSection(props: Props): ReactElement<Props> {
  return (
    <div className="about">
      <SideBar
        title="About"
        color="white"
        backgroundColor="primary"
        menu={props.menu}
      />
      <div className="about__right-section">
        <div className="about__right-text-container">
          <Typography variant="h6">
            <strong>
              At UpGetGo, we build high quality, bespoke websites.
            </strong>
          </Typography>
          <br />
          <Typography variant="subtitle1">
            Our thorough web design process will deeply capture your companies
            identity and vision, and advertise it to the world in the form of a
            modern slick website.
          </Typography>
          <br />
          <br />
          <br />
          <Typography variant="h5">
            <strong>Our Web Design Process</strong>
          </Typography>
          <br />
          <Typography variant="subtitle1">
            We follow a 7 step process from plan to publish with designing and
            building websites with our clients:
          </Typography>
          {steps.map((step) => (
            <StepProcess
              key={step.header}
              content={step.content}
              header={step.header}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default AboutSection;
