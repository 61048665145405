import "./websites.css";
import { ReactElement } from "react";
import SideBar from "../../components/SideBar";
import { MenuProps } from "../../components/Menu";
import WebsiteContainer from "../../components/WebsiteContainer";

interface Props {
  menu: ReactElement<MenuProps>;
}

function WebsitesSection(props: Props): ReactElement<Props> {
  return (
    <div className="websites">
      <SideBar
        title="Websites"
        subtitle="View our modern websites built for many industries"
        color="black"
        backgroundColor="white"
        menu={props.menu}
        mobileBackgroundColor="secondary"
        mobileColor="white"
      />
      <div className="websites__right-container">
        <WebsiteContainer />
      </div>
    </div>
  );
}

export default WebsitesSection;
