import "./menu.css";
import { Typography } from "@mui/material";
import { ReactElement, RefObject } from "react";

export interface MenuProps {
  color: "primary" | "white" | "black";
  aboutRef: RefObject<HTMLDivElement>;
  websitesRef: RefObject<HTMLDivElement>;
  contactRef: RefObject<HTMLDivElement>;
}

function Menu(props: MenuProps): ReactElement<MenuProps> {
  // If the color is black we assume the background of the element is white.
  // Hence we set a different style to show a border with the primary color on hover.
  const linkStyle =
    props.color === "black" ? "menu__link-primary" : "menu__link";

  return (
    <div className="menu__container">
      <ul>
        <li>
          <Typography
            className={linkStyle}
            onClick={() =>
              props.aboutRef.current?.scrollIntoView({ behavior: "smooth" })
            }
            color={props.color}
            variant="subtitle1"
          >
            ABOUT
          </Typography>
        </li>
        <li>
          <Typography color={props.color} variant="subtitle1">
            /
          </Typography>
        </li>
        <li>
          <Typography
            className={linkStyle}
            onClick={() =>
              props.websitesRef.current?.scrollIntoView({ behavior: "smooth" })
            }
            color={props.color}
            variant="subtitle1"
          >
            WEBSITES
          </Typography>
        </li>
        <li>
          <Typography color={props.color} variant="subtitle1">
            /
          </Typography>
        </li>
        <li>
          <Typography
            className={linkStyle}
            onClick={() =>
              props.contactRef.current?.scrollIntoView({ behavior: "smooth" })
            }
            color={props.color}
            variant="subtitle1"
          >
            CONTACT
          </Typography>
        </li>
      </ul>
    </div>
  );
}

export default Menu;
