import "./website-container.css";
import lpc from "./lpc.png";
import fgaGroup from "./fga-group.png";
import beautifulWomanRuislip from "./beautiful-woman-ruislip.png";
import monitor from "./monitor.png";
import type { Website } from "../../types";
import londonInsulation from "./london-insulation.png";
import gwe from "./gwe.png";
import { ReactElement, useState } from "react";
import { Button, Typography } from "@mui/material";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import Spinner from "../Spinner";

const websites: Website[] = [
  {
    name: "London Insulation Services",
    subHeading: "Insulation ductwork service contractor",
    industry: "Construction",
    imageUrl: londonInsulation,
    url: "https://www.londoninsulationservices.co.uk/",
  },
  {
    name: "Grant Walker Engineering",
    subHeading: "Electrical and Mechanical engineering Company",
    industry: "Construction",
    imageUrl: gwe,
    url: "https://www.g-w-e.com/",
  },
  {
    name: "FGA Group",
    subHeading:
      "Commercial and agricultural buildings, machinery and servicing, ",
    industry: "Agricultural Machinery",
    imageUrl: fgaGroup,
    url: "https://www.fgagroup.co.uk/",
  },
  {
    name: "Leonard Peters Consulting",
    subHeading: "Mechanical, Electrical and Public Health design consultancy",
    industry: "Engineering",
    imageUrl: lpc,
    url: "https://www.leonardpetersconsulting.com",
  },
  {
    name: "Beautiful Woman Ruislip",
    subHeading: "Local hair and beauty salon",
    industry: "Hair and Beauty",
    imageUrl: beautifulWomanRuislip,
    url: "https://www.beautifulwomanruislip.co.uk/",
  },
];

function WebsiteContainer(): ReactElement {
  const [loading, setLoading] = useState(false);
  const [website, setWebsite] = useState<Website>(websites[0]);

  const handleClickBack = () => {
    setLoading(true);
    const websiteIndex = websites.findIndex(
      (selectedWebsite) => website.name === selectedWebsite.name
    );

    setTimeout(() => {
      if (websiteIndex <= 0) {
        setWebsite(websites[websites.length - 1]);
      } else {
        setWebsite(websites[websiteIndex - 1]);
      }
      setLoading(false);
    }, 500);
  };

  const handleClickForward = () => {
    setLoading(true);
    const websiteIndex = websites.findIndex(
      (selectedWebsite) => selectedWebsite.name === website.name
    );

    setTimeout(() => {
      if (websiteIndex >= websites.length - 1) {
        setWebsite(websites[0]);
      } else {
        setWebsite(websites[websiteIndex + 1]);
      }

      setLoading(false);
    }, 500);
  };

  return (
    <div className="website-container__text-container">
      <Typography variant="h4" color="inherit" align="center">
        {website.name}
      </Typography>
      <Typography
        component="p"
        variant="subtitle1"
        color="inherit"
        align="center"
      >
        {website.subHeading}
      </Typography>
      <div className="website-container__image-container">
        {loading ? (
          <div className="website-container__spinner">
            <Spinner />
          </div>
        ) : (
          <img
            className="website-container__website-image"
            src={website.imageUrl}
            alt={website.name}
          />
        )}
        <img
          className="website-container__monitor-image"
          src={monitor}
          alt={website.name}
        />
      </div>
      <div>
        <Button
          onClick={() => window.open(website.url, "_blank")}
          size="large"
          variant="contained"
        >
          View Website
        </Button>
      </div>
      <div className="website-container__arrow-container">
        <ArrowLeft onClick={handleClickBack} color="inherit" />
        <ArrowRight onClick={handleClickForward} color="inherit" />
      </div>
    </div>
  );
}

export default WebsiteContainer;
