import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#F7484E",
    },
    secondary: {
      main: "#16302B",
    },
  },
  typography: {
    fontFamily: "proxima-nova, sans-serif",
    h1: {
      fontFamily: "'Poppins', sans-serif",
    },
  },
});

export { theme };
