import "./mobile-menu.css";
import { ReactElement, useState, RefObject } from "react";
import { Close, Menu } from "@mui/icons-material";
import { Fade, Typography } from "@mui/material";

interface Props {
  aboutRef: RefObject<HTMLDivElement>;
  websitesRef: RefObject<HTMLDivElement>;
  contactRef: RefObject<HTMLDivElement>;
}

function MobileMenu(props: Props): ReactElement<Props> {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div onClick={() => setOpen(!open)} className="mobile-menu__button">
        <Fade
          timeout={{
            appear: 1000,
            enter: 500,
            exit: 500,
          }}
          in={!open}
        >
          <Menu />
        </Fade>
        <Fade
          timeout={{
            appear: 1000,
            enter: 500,
            exit: 500,
          }}
          in={open}
        >
          <Close />
        </Fade>
      </div>
      <Fade in={open}>
        <div className="mobile-menu__main">
          <ul>
            <li>
              <Typography
                className=""
                onClick={() => {
                  setOpen(false);

                  props.aboutRef.current?.scrollIntoView({
                    behavior: "smooth",
                  });
                }}
                color="primary"
                variant="subtitle1"
                component="p"
              >
                ABOUT
              </Typography>
            </li>
            <li>
              <Typography
                className=""
                onClick={() => {
                  setOpen(false);
                  props.websitesRef.current?.scrollIntoView({
                    behavior: "smooth",
                  });
                }}
                color="primary"
                variant="subtitle1"
                component="p"
              >
                WEBSITES
              </Typography>
            </li>
            <li>
              <Typography
                className=""
                onClick={() => {
                  setOpen(false);

                  props.contactRef.current?.scrollIntoView({
                    behavior: "smooth",
                  });
                }}
                color="primary"
                variant="subtitle1"
                component="p"
              >
                CONTACT
              </Typography>
            </li>
          </ul>
        </div>
      </Fade>
    </>
  );
}
export default MobileMenu;
