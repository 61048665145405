import "./home.css";
import { ReactElement } from "react";
import { Typography } from "@mui/material";
import { MenuProps } from "../../components/Menu";
import logo from "./logo.png";

interface Props {
  menu: ReactElement<MenuProps>;
}

function HomeSection(props: Props): ReactElement<Props> {
  return (
    <div className="home">
      <div className="home__container">
        <Typography
          align="center"
          component="h6"
          variant="subtitle2"
          color="primary"
        >
          WEB DESIGN
        </Typography>
        <div className="home__logo-container">
          <img alt="logo" src={logo} />
          <Typography component="h1" align="center" variant="h1" color="white">
            UpGetGo
          </Typography>
        </div>
        <div className="home__menu-container">{props.menu}</div>
      </div>
    </div>
  );
}

export default HomeSection;
