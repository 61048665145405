import "./App.css";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import HomeSection from "./sections/Home";
import AboutSection from "./sections/About";
import WebsitesSection from "./sections/Websites";
import ContactSection from "./sections/Contact";
import { useRef } from "react";
import Menu from "./components/Menu";
import MobileMenu from "./components/MobileMenu";

function App() {
  const aboutRef = useRef<HTMLDivElement>(null);
  const websitesRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);

  return (
    <ThemeProvider theme={theme}>
      <MobileMenu
        aboutRef={aboutRef}
        websitesRef={websitesRef}
        contactRef={contactRef}
      />
      <HomeSection
        menu={
          <Menu
            color="primary"
            aboutRef={aboutRef}
            websitesRef={websitesRef}
            contactRef={contactRef}
          />
        }
      />
      <div ref={aboutRef}>
        <AboutSection
          menu={
            <Menu
              color="white"
              aboutRef={aboutRef}
              websitesRef={websitesRef}
              contactRef={contactRef}
            />
          }
        />
      </div>
      <div ref={websitesRef}>
        <WebsitesSection
          menu={
            <Menu
              color="black"
              aboutRef={aboutRef}
              websitesRef={websitesRef}
              contactRef={contactRef}
            />
          }
        />
      </div>
      <div ref={contactRef}>
        <ContactSection
          menu={
            <Menu
              color="primary"
              aboutRef={aboutRef}
              websitesRef={websitesRef}
              contactRef={contactRef}
            />
          }
        />
      </div>
    </ThemeProvider>
  );
}

export default App;
